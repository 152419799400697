import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/ob/interview_iwase.jpg';
import okabe from '../images/map/okabe.png';
import okabeImg from '../images/map/okabeImg.jpg';
// import okabeTop from '../images/ob/ob_okabe_top.jpg';
// import * as ROUTES from '../src/constants/routes';

class OkabePage extends React.Component {

  render() {
    return (
      <Fragment>
      <title>地方部員インタビュー　岡部雅大|國學院大學久我山高等学校　バスケットボール部</title>
      <h2 className="content-title-black-ob">地方部員インタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'black', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">2年 岩瀬</h4>
          <div className="mng-text">
          國學院大學久我山高校の入学理由を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'black'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={okabe} />}
        >
          <h4 className="vertical-timeline-element-subtitle">3年 岡部雅大</h4>
          <div className="ob-text">
          はい。私の場合は、両親から勧められたということもありますが、何よりも久我山高校バスケ部が、これまで何度も全国大会に出場している実績ある学校だったので、それが決め手です。また、興味を持って高校や部活動の事を、調べていくうちに久我山高校は部活面だけでなく、勉強面にも力を入れていることが分かったので、高いレベルで文武両道を実践したいと思い、久我山高校への入学を決めました。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          部活面の実績はもちろん、勉強面も高いレベルで挑戦できるのは久我山高校の良いところですよね。実際に入学してみて感じた、久我山高校の良いところをもう少し具体的に教えて頂けますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={okabe} />}
        >
          <div className="ob-text">
          はい。部活面では、先ほども言ったようにインターハイ、ウィンターカップなどの全国大会に出場出来るところです。実は、自分たちの代が入学してからは、全国大会に出場する事が出来なき厳しい状況が続いたのですが、昨年の冬のウィンターカップでようやく全国大会に出場することができました！勉強面においては、小テストがこまめにあるので、常に自分の実力をチェックすることができますし、自分の場合は仲の良い友達と切磋琢磨する事で勉強のモチベーションを上げています。
</div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obImg" alt="sakai" src={okabeImg} />
</div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          確かに久我山高校は小テストがありますが、日々勉強に向き合う良いきっかけになっていると思います。では、次に久我山高校バスケ部の雰囲気を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={okabe} />}
        >
          <div className="ob-text">
          はい。普段の日常生活や放課後では先輩後輩の上下関係はしっかりとありますが、いざ練習が始まれば上下関係はなく、お互いに意見を言い合える環境があります。部活中は真剣勝負への取り組みなのでピリッとした雰囲気もありますが、基本的には、先輩・後輩関係なく仲がいいので過ごしやすい雰囲気だと思います。ぜひ久我山高校バスケ部の練習体験に来てもらい、雰囲気を感じとってもらえればと思います。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
岡部さんの言う通り、先輩後輩関係なく仲が良い所は、私の久我山高校バスケ部の好きな所の１つです！話は少し変わりますが、岡部さんは実家が茨城県との事ですが、東京にある久我山高校に通う上で心配だったことはありますか。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={okabe} />}
        >
          <div className="ob-text">
          東京だから心配って事は特にありませんが、地方からの通学という所で不安は多少ありましたね。自分は、あまり朝が強くなかったので、早起きできるかとても心配でした。また通学時間が長い分、家での勉強時間を確保できるかも心配でした。ですが、電車での通学時間を勉強に充てるなどして、弱みを強みに変えるような工夫をしています。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。通学時間が長いとの事ですが、地方からなぜ久我山高校に通おうと思ったのでしょうか。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={okabe} />}
        >
          <div className="ob-text">
          はい。やはり全国に出場できる可能性が高く、また勉強面にも力を入れていて大学進学も十分に考えられたので、多少通学時間が長くても入学する価値があるなと思ったからです。全国的に見ても、部活面と勉強面でここまでレベルが高い学校はそう多くないですからね。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          本当に久我山高校の文武両道のレベルは高く毎日充実した生活を送れています。では最後に、久我山高校バスケ部に興味を持っている中学生に向けてメッセージを下さい！
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={okabe} />}
        >
          <div className="ob-text">
          少し厳しい言い方かもしれませんが、部活だけ頑張ろうと思って入学すると、勉強面で苦労するかと思います。部活では、本気で全国を目指し、勉強でも良い成績を残せるように頑張る。そのような覚悟がある方の御入学をお待ちしてます。
        </div>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <OkabePage />
  </Layout>
);
